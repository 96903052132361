<template>
  <div class="calendar page" id="FitterPage">
    <div class="content" v-loading="loading">
      <div class="page_header">
        <h1>Вполненные заявки</h1>
      </div>
      <div class="list" v-if="list.length">
        <div class="box" v-for="event in list" :key="event.id" @click="openEvent(event.id)">
          <div class="title">
            <span class="type" v-if="event.type === 'assessment'">Замер</span>
            <span class="type" v-else-if="event.type === 'mounting'">Монтаж</span>
            <span class="time">
              {{ moment(event.start_date).format('DD.MM.YYYY') }}
              с {{ moment(event.start_time, 'HH:mm:ss').format('HH:mm') }}
              до {{ moment(event.end_time, 'HH:mm:ss').format('HH:mm') }}
            </span>
          </div>
          <div class="who">
            <span class="brigade" v-if="event.brigade_id">Бригада: {{event.brigade.title}}</span>
            <span class="brigade" v-if="event.fitter_id">Монтажник: {{event.fitter.fio_short}}</span>
          </div>
          <div class="address">
            Адрес: <span>{{event.address.value}}</span>
          </div>
          <div class="comment" v-if="event.manager_comment">
            Комментарий менеджера: <div>{{event.manager_comment}}</div>
          </div>
        </div>
      </div>
      <div class="empty" v-else>
        <p v-if="loading">Загрузка...</p>
        <p v-else>У вас нет назначенных заявок</p>
      </div>
    </div>
  </div>

</template>

<script>
import {mapState} from 'vuex'
import {actionTypes as calendarActions} from "@/store/modules/calendar";

export default {
  name: "Complete",
  data() {
    return {}
  },
  computed: {
    ...mapState({
      list: state => state.calendar.filteredList,
      loading: state => state.calendar.loading
    })
  },
  methods: {
    loadData() {
      this.$store.dispatch(calendarActions.getFitterList, {status: 'complete'})
    },
    openEvent(id){
       this.$router.push({path: "/fitter/event/" + id});
    }
  },
  mounted() {
    this.loadData()
  },
  created() {
    document.title = this.$route.meta.title ? this.$route.meta.title : 'GlassERP'
  },
}
</script>
